$form-input-height: $spacing-lrg;
$form-input-file-height: $spacing-xxxl;

.form {
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    &__title {
        margin-bottom: $spacing-med;
        color: $color-white;
    }

    &__item {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        margin: $spacing-med 0;

        &:first-of-type {
            margin-top: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        label {
            margin-bottom: $spacing-sml;
        }

        input,
        textarea {
            width: 100%;
            box-sizing: border-box;
            height: $form-input-height;
            padding: $spacing-sml;
            border: none;
            background-color: $color-gray;
        }

        textarea {
            min-height: $spacing-xxxl;
            resize: vertical;
        }
    }

    .button-group {
        margin-top: $spacing-med;

        @include mq($mq-tab--sml) {
            margin-top: auto;
        }
    }

    &__error {
        margin-top: 0;
        color: $color-primary-dark;
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
        margin-top: $spacing-med;

        &-item {
            color: $color-white;
            cursor: pointer;
            margin: 0 $spacing-sml;

            &:first-of-type {
                margin-left: 0;
            }

            &:last-of-type {
                margin-right: 0;
            }
        }
    }
}
