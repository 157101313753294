$font-weight-light: 200;
$font-weight-normal: 400;
$font-weight-bold: 600;

@font-face {
	font-family: 'Brando Sans';
	src: local('Brando Sans Light'), local('BrandoSans-Light'),
		url('/static/fonts/BrandoSans-Light.woff2') format('woff2'),
		url('/static/fonts/BrandoSans-Light.woff') format('woff'),
		url('/static/fonts/BrandoSans-Light.ttf') format('truetype');
	font-weight: $font-weight-light;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Brando Sans';
	src: local('Brando Sans SemiBold'), local('BrandoSans-SemiBold'),
		url('/static/fonts/BrandoSans-SemiBold.woff2') format('woff2'),
		url('/static/fonts/BrandoSans-SemiBold.woff') format('woff'),
		url('/static/fonts/BrandoSans-SemiBold.ttf') format('truetype');
	font-weight: $font-weight-bold;
	font-style: normal;
	font-display: swap;
}
