.game-score {
    display: flex;
    justify-content: space-between;

    &__item {
        width: 100%;
        margin: 0 $spacing-xxs;
        text-align: center;

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    &__heading {
        font-weight: $font-weight-bold;
        color: $color-base-lighter;
    }

    &__value {
        color: $color-primary;
    }
}
