/* COLORS */
$color-primary: #fb3e44;
$color-primary-dark: darken($color-primary, 5%);
$color-primary-light: lighten($color-primary, 5%);
$color-primary-lighter: lighten($color-primary, 10%);
$color-primary-lightest: lighten($color-primary, 15%);

/* COLOR GRAY */
$color-white: #fff;
$color-black: #39393a;

$color-gray: #f8f4f4;

$color-gray-darker: darken($color-gray, 10%);
$color-gray-dark: darken($color-gray, 5%);

$color-gray-light: lighten($color-gray, 5%);
$color-gray-lighter: lighten($color-gray, 10%);
$color-gray-lightest: lighten($color-gray, 15%);

/* COLOR BASE */
$color-base: #2f2f2f;

$color-base-darker: darken($color-base, 10%);
$color-base-dark: darken($color-base, 5%);

$color-base-light: lighten($color-base, 5%);
$color-base-lighter: lighten($color-base, 10%);
$color-base-lightest: lighten($color-base, 15%);
