/**
 * Mixin to provide short-hand media query syntax
 *
 * @param {Number} $viewport - Size of the viewport
 * @param {String} $type-of-constraint - Orientation of targeted media query
 */

 @mixin mq($viewport, $type-of-constraint: width) {
    @media screen and (min-#{$type-of-constraint}: $viewport) {
        @content;
    }
}

/**
 * Example:
 *
 * ---- for min-width media query ----
 *
 *  @include mq(1080px) {
 *      display: none;
 *  }
 *
 * ---- for min-height media query ----
 *
 *  @include mq(1080px, height) {
 *      display: none;
 *  }
 */
