/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

/**
 * Mixin to provide rem and px fallback units for font sizes
 *
 * @param {Number} $size - Size of font based on rem units
 */
/*
 * @WARNING: Here be dragons
 *
 * @NOTE: Please don't mess with these unless you're prepared to spend the rest of your day
 * @NOTE: figuring out what broke where. Browser support for word-break is very tricky. GL!
 */
/**
 * Mixin to provide short-hand media query syntax
 *
 * @param {Number} $viewport - Size of the viewport
 * @param {String} $type-of-constraint - Orientation of targeted media query
 */
/**
 * Example:
 *
 * ---- for min-width media query ----
 *
 *  @include mq(1080px) {
 *      display: none;
 *  }
 *
 * ---- for min-height media query ----
 *
 *  @include mq(1080px, height) {
 *      display: none;
 *  }
 */
/* COLORS */
/* COLOR GRAY */
/* COLOR BASE */
/** Containers */
/** Block */
/** Components */
/** Transition & animation */
/** Media query */
@font-face {
  font-family: 'Brando Sans';
  src: local("Brando Sans Light"), local("BrandoSans-Light"), url("/static/fonts/BrandoSans-Light.woff2") format("woff2"), url("/static/fonts/BrandoSans-Light.woff") format("woff"), url("/static/fonts/BrandoSans-Light.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Brando Sans';
  src: local("Brando Sans SemiBold"), local("BrandoSans-SemiBold"), url("/static/fonts/BrandoSans-SemiBold.woff2") format("woff2"), url("/static/fonts/BrandoSans-SemiBold.woff") format("woff"), url("/static/fonts/BrandoSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

body {
  font-family: 'Brando Sans', 'Helvetica', 'Book Antiqua',Palatino,'Palatino Linotype','Palatino LT STD',Georgia,serif; }

p {
  font-size: 16px;
  line-height: 1.6; }

h1, .h1 {
  font-size: 72px;
  line-height: 1.2;
  font-weight: normal;
  font-feature-settings: 'lnum';
  margin: 0;
  margin-bottom: 8px; }

h2, .h2 {
  font-size: 40px;
  line-height: 1.2;
  font-weight: normal;
  font-feature-settings: 'lnum';
  margin: 0;
  margin-bottom: 8px; }

h3, .h3 {
  font-size: 40px;
  line-height: 1.2;
  font-weight: normal;
  font-feature-settings: 'lnum';
  margin: 0;
  margin-bottom: 8px; }

h4, .h4 {
  font-size: 24px;
  line-height: 1.2;
  font-weight: normal;
  font-feature-settings: 'lnum';
  margin: 0;
  margin-bottom: 8px; }

h5, .h5 {
  font-size: 20px;
  line-height: 1.2;
  font-weight: normal;
  font-feature-settings: 'lnum';
  margin: 0;
  margin-bottom: 8px; }

h6, .h6 {
  font-size: 18px;
  line-height: 1.2;
  font-weight: normal;
  font-feature-settings: 'lnum';
  margin: 0;
  margin-bottom: 8px; }

a {
  font-weight: 600;
  color: #fb3e44;
  transition: color 0.25s cubic-bezier(0.4, 0, 0.2, 1); }
  a:hover {
    color: #fa252c; }

.copy--faded {
  color: #555555; }

.copy--primary {
  color: #fb3e44; }

body, #root {
  min-height: 100vh; }

body {
  background-color: #f8f4f4; }
  body.no-scroll {
    overflow: hidden; }

main > section {
  margin: 8px 0; }

/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/
.js-focus-visible :focus:not(.focus-visible) {
  outline: none; }

.fade-up-appear, .fade-up-enter {
  opacity: 0.01;
  transform: translateY(16px); }

.fade-up-appear-active, .fade-up-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out; }

.fade-up-exit {
  opacity: 1; }

.fade-up-exit-active {
  opacity: 0.01;
  transform: translateY(16px);
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out; }

.fade-down-appear, .fade-down-enter {
  opacity: 0.01;
  transform: translateY(-16px); }

.fade-down-appear-active, .fade-down-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out; }

.fade-down-exit {
  opacity: 1; }

.fade-down-exit-active {
  opacity: 0.01;
  transform: translateY(-16px);
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out; }

.grid {
  padding: 0 24px; }
  .grid .g2,
  .grid .g4,
  .grid .g6 {
    grid-column: main-inner-start / main-inner-end; }
  .grid .g0 {
    grid-column: main-start / main-end; }
  @media screen and (min-width: 768px) {
    .grid {
      display: grid;
      grid-gap: 24px 16px;
      grid-template-columns: [main-start] 1fr [main-inner-start] 1fr [content-start] 1fr [body-start] 6fr [body-end] 1fr [content-end] 1fr [main-inner-end] 1fr [main-end];
      padding: 0; }
      .grid .g0 {
        grid-column: main-start / main-end; }
      .grid .g2 {
        grid-column: main-inner-start / main-inner-end; }
      .grid .g4 {
        grid-column: content-start / content-end; }
      .grid .g6 {
        grid-column: body-start / body-end; } }

.form {
  display: flex;
  flex-direction: column;
  justify-content: stretch; }
  .form__title {
    margin-bottom: 24px;
    color: #fff; }
  .form__item {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    margin: 24px 0; }
    .form__item:first-of-type {
      margin-top: 0; }
    .form__item:last-of-type {
      margin-bottom: 0; }
    .form__item label {
      margin-bottom: 16px; }
    .form__item input,
    .form__item textarea {
      width: 100%;
      box-sizing: border-box;
      height: 48px;
      padding: 16px;
      border: none;
      background-color: #f8f4f4; }
    .form__item textarea {
      min-height: 128px;
      resize: vertical; }
  .form .button-group {
    margin-top: 24px; }
    @media screen and (min-width: 768px) {
      .form .button-group {
        margin-top: auto; } }
  .form__error {
    margin-top: 0;
    color: #fa252c; }
  .form__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px; }
    .form__footer-item {
      color: #fff;
      cursor: pointer;
      margin: 0 16px; }
      .form__footer-item:first-of-type {
        margin-left: 0; }
      .form__footer-item:last-of-type {
        margin-right: 0; }

.button {
  height: 48px;
  min-width: 128px;
  border: none;
  border-radius: 4px;
  background-color: #494949;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1); }
  .button:disabled {
    cursor: not-allowed; }
  .button:hover:not(:disabled), .button:focus:not(:disabled) {
    background-color: #3c3c3c; }
  .button--primary {
    background-color: #fb3e44;
    color: #fff; }
    .button--primary:hover:not(:disabled), .button--primary:focus:not(:disabled) {
      background-color: #fa252c; }

.button-group {
  display: flex;
  flex-direction: column;
  justify-content: stretch; }
  @media screen and (min-width: 768px) {
    .button-group {
      flex-direction: row; } }
  @media screen and (min-width: 768px) {
    .button-group .button {
      flex: 1; } }
  .button-group .button + .button {
    margin-top: 24px; }
    @media screen and (min-width: 768px) {
      .button-group .button + .button {
        margin-top: 0;
        margin-left: 24px; } }

.card {
  padding: 24px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 10px rgba(57, 57, 58, 0.1); }

.game-score {
  display: flex;
  justify-content: space-between; }
  .game-score__item {
    width: 100%;
    margin: 0 4px;
    text-align: center; }
    .game-score__item:first-of-type {
      margin-left: 0; }
    .game-score__item:last-of-type {
      margin-right: 0; }
  .game-score__heading {
    font-weight: 600;
    color: #494949; }
  .game-score__value {
    color: #fb3e44; }

@keyframes rotation {
  to {
    transform: rotate(360deg); } }

.loader {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; }
  .loader__spinner {
    height: 64px;
    width: 64px;
    margin: auto;
    border: 1px solid white;
    border-top-color: #fb3e44;
    border-radius: 50%;
    animation: rotation 1.5s infinite linear; }

.package-metadata__meta, .package-metadata__links {
  font-size: 14px;
  line-height: 20px;
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  list-style: none; }
  .package-metadata__meta li, .package-metadata__links li {
    margin: 0 4px;
    padding-right: 8px; }
    .package-metadata__meta li:first-of-type, .package-metadata__links li:first-of-type {
      margin-left: 0; }
    .package-metadata__meta li:last-of-type, .package-metadata__links li:last-of-type {
      margin-right: 0;
      padding-right: 0;
      border-right: 0; }

.package-metadata__meta {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #f8f4f4; }
  .package-metadata__meta li {
    border-right: 1px solid #555555; }

.package-metadata__links {
  display: flex;
  padding: 16px 0 0; }
  .package-metadata__links a {
    text-decoration: none; }

.package-metadata__message {
  margin: 48px 0;
  text-align: center; }

.site-header {
  height: 128px;
  padding: 24px;
  margin-bottom: 24px; }
  @media screen and (min-width: 820px) {
    .site-header--full {
      height: 40vh;
      margin-bottom: 24px; } }
  @media screen and (min-width: 820px) {
    .site-header {
      padding: 24px 0; } }
  .site-header__logo-wrapper {
    position: relative;
    display: inline-block;
    height: 128px; }
    @media screen and (min-width: 820px) {
      .site-header--full .site-header__logo-wrapper {
        height: 40vh; } }
    .site-header__logo-wrapper a {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      font-size: 0; }
  .site-header__logo svg {
    height: 100%;
    max-height: 128px;
    transition: max-height 0.15s cubic-bezier(0.4, 0, 0.2, 1); }
    .site-header--full .site-header__logo svg {
      max-height: 40vh; }
