/** Containers */
$container-sml: 460px;
$container-med: 890px;
$container-lrg: 1140px;
$container-content: 40em;

/** Block */
$spacing-base: 8px;
$spacing-xxs: $spacing-base / 2; // 4px
$spacing-xs: $spacing-base; // 8px
$spacing-sml: $spacing-base * 2; // 16px
$spacing-med: $spacing-base * 3; // 24px
$spacing-lrg: $spacing-base * 6; // 48px
$spacing-xl: $spacing-base * 8; // 64px
$spacing-xxl: $spacing-base * 12; // 96px
$spacing-xxxl: $spacing-base * 16; // 128px

/** Components */
$site-header-height: 120px;
$border-radius-sml: 4px;
$border-radius-med: 8px;
$border-radius-lrg: 12px;
$border-radius-xl: 16px;
$box-shadow-default--soft: 0 5px 10px rgba($color-black, 0.1);
$box-shadow-default--softer: 0 12px 24px rgba($color-black, 0.1);

/** Transition & animation */
$transition-timing-fast: 0.15s;
$transition-timing-med: 0.25s;
$transition-timing-slow: 0.4s;
$transition-timing-slower: 0.6s;
$transition-timing-very-slow: 1s;
$transition-timing-slowest: 1.5s;
$transition-easing-default: ease-in-out;
$transition-easing-out: ease-out;
$transition-easing-cubic: cubic-bezier(0.4, 0, 0.2, 1);

/** Media query */
$mq-mob: 480px;
$mq-mob--lrg: 640px;

$mq-tab--sml: 768px;
$mq-tab--lrg: 820px;

$mq-desk--sml: 960px;
$mq-desk--med: 1200px;
$mq-desk--lrg: 1440px;
