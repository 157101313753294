body, #root {
  min-height: 100vh;
}

body {
  background-color: $color-gray;

  &.no-scroll {
    overflow: hidden;
  }
}

main {
  > section {
    margin: $spacing-xs 0;
  }
}

/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/
.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}
